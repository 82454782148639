// import logo from "../assets/logo.png";
function Footer() {
  return (
    <div className="footer">
      {/* <img src={logo} alt="NBRLYU logo" /> */}
      <p>✨ Created by Team NBRLYU @Co.Lab 🚀2022 ✨</p>
    </div>
  );
}
export default Footer;
